import React, { memo, useEffect, useState } from "react";
import { Select, Button, Typography } from "antd";
import "./style.scss";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import axios from "axios";
import { $nexusUrlHost } from "../../http";
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "config/token";

const { Title } = Typography;

type AccountType = {
    account_id: string,
    account_name: string
};

type DomainType = {
    domain_id: string,
    domain_name: string,
    auth_url: string
};

const MLManage: React.FC = () => {
    const [size, setSize] = useState<SizeType>("large");
    const [accounts, setAccounts] = React.useState<AccountType[]>([]);
    const [domains, setDomains] = React.useState<DomainType[]>([]);
    const [authURL, setAuthURL] = React.useState<string>();
    const [selectedDomainID, setselectedDomainID] = React.useState<string>();
    const msalInstance = useMsal();

    useEffect(() => {

        const fetchData = async () => {
            const token = await getAccessToken(msalInstance);
            
            const bearerToken = "Bearer " + (token == undefined ? "" : token.token);

            const requestURL = location.host.indexOf("dev") > -1 ? 
                "https://ml-services.992382651944.cloud.bayer.com/api/v1/ml-service/default" : 
                "https://ml-services.471112520358.cloud.bayer.com/api/v1/ml-service/default";
        
            axios
            .get(requestURL, {
                    headers: {
                        "Authorization" : bearerToken,
                    },
                })
            .then((res) => {
               setAccounts(res.data.accounts);
               setDomains(res.data.domains);
               setselectedDomainID(res.data.domains[0].domain_id);
               setAuthURL(res.data.domains[0].auth_url);
            });
         };

         fetchData();
    }, []);


    const handleAccountChange = async (value: string) => {

        const token = await getAccessToken(msalInstance);

        const bearerToken = "Bearer " + (token == undefined ? "" : token.token);

        const requestURL = location.host.indexOf("dev") > -1 ? 
            "https://ml-services.992382651944.cloud.bayer.com/api/v1/ml-service/get_domains?account_id=" + (value).toString() : 
            "https://ml-services.471112520358.cloud.bayer.com/api/v1/ml-service/get_domains?account_id=" + (value).toString();

        axios
           .get(requestURL, {
                headers: {
                    "Authorization" : bearerToken,
                },
           })
           .then((res) => {
               setDomains(res.data);
               setselectedDomainID(res.data[0].domain_id);
               setAuthURL(res.data[0].auth_url);
           });
    };

    const onDomainChange = (value: string) => {
        const selDomain = domains.find((domain) => domain.domain_id === value);
        setselectedDomainID(value);
        setAuthURL(selDomain?.auth_url);
    };

    const onOpenBtnClick = () => {
        window.open(authURL, "_self", "noreferrer");
    };

    return (
        <div className="main1">
            <Title level={1} className="main-title">
                Welcome to the Gateway to your ML Development Environment
            </Title>
            <div className="main-subtitle">
                <div>Your account has <b>multiple SageMaker domains</b></div> 
                <div>Choose the one you want to connect to:</div>
            </div>

            <div className="main-descr">
                <div>
                    {
                        (accounts.length > 0) &&
                        <Select size={size}
                            defaultValue={accounts[0].account_id}
                            style={{ width: 300 }}
                            onChange={handleAccountChange}
                            options={accounts.map((acc: AccountType) => ({ label: acc.account_name, value: acc.account_id }))}
                        />
                    }
                </div>
                <div className="mt">
                    {
                        (domains.length > 0) &&
                        <Select
                            value={selectedDomainID}
                            style={{ width: 300 }}
                            onChange={onDomainChange}
                            options={domains.map((d: DomainType) => ({ label: d.domain_name, value: d.domain_id }))}
                        />
                    }
                </div>
                <div className="mt">
                    <Button type="primary" style={{ width: 300 }} onClick={onOpenBtnClick}>Open Sagemaker Studio</Button>
                </div>
            </div>
        </div>
    );
};

export default memo(MLManage);
