import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  LoginOutlined,
  LogoutOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { MenuProps, Menu, Dropdown, Space } from "antd";

import { isDevelopment } from "const";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import SupportMenu from "../SupportMenu";
import Button from "common/Button";
import { useLogin } from "hooks/useLogin";

import "./style.scss";

const Header: React.FC = () => {
  const msalInstance = useMsal();
  const { login, logout } = useLogin(msalInstance);

  const handleLogin = useCallback(() => login({ reloadPage: false }), [login]);

  const [current, setCurrent] = useState("");
  const isAuthenticated = useIsAuthenticated();

  const accountName: string | undefined = msalInstance.accounts[0]?.name;

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      current !== "list" && setCurrent("list");
    } else if (location.pathname === "/mlManage") {
      current !== "add" && setCurrent("add");
    } else {
      setCurrent("");
    }
  }, [location.pathname, current]);

  const menuItemsSwitch: MenuProps["onClick"] = event => {
    setCurrent(event.key);
  };

  const logoutItem = useMemo(
    () => (
      <Menu
        items={[
          {
            label: (
              <div onClick={logout} className="header-logout-btn">
                <LogoutOutlined className="logout-icon" />
                Log out
              </div>
            ),
            key: "0",
            className: "header-logout",
          },
        ]}
      />
    ),
    [logout],
  );

  return (
    <header
      className={`header ${
        isDevelopment || isAuthenticated ? "" : "header-auth"
      }`}
    >
      <div className="header-left">
        <Link to="/home" className="logo">
          PH DSE Nexus
        </Link>
        {isDevelopment || isAuthenticated ? (
          <Menu
            onClick={menuItemsSwitch}
            selectedKeys={[current]}
            mode="horizontal"
            disabledOverflow
            className="header-menu"
            items={[
              {
                key: "list",
                icon: <UnorderedListOutlined className="menu-icon" />,
                className: "menu-item",
                label: <Link to="/">Data Gateway</Link>,
              },
              {
                key: "add",
                icon: <PlusOutlined className="menu-icon" />,
                className: "menu-item",
                label: <Link to="/mlManage">ML Manage</Link>,
              },
            ]}
          />
        ) : null}
      </div>
      {isDevelopment || isAuthenticated ? (
        <div className="header-right">
          <SupportMenu />
          <Dropdown overlay={logoutItem} trigger={["click"]}>
            <div className="header-account">
              <Space>{accountName}</Space>
            </div>
          </Dropdown>
        </div>
      ) : (
        <Button
          icon={<LoginOutlined />}
          onClick={handleLogin}
          type="primary"
          size="large"
        >
          Login
        </Button>
      )}
    </header>
  );
};

export default memo(Header);
